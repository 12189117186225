.chat-head {
  width: 40px;
  height: 40px;
}
.tab-body-seen{
  position: relative;
  border-bottom: 1px solid var(--boot-gray);
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  background-color: var(--holo-blue);
  color: black;
  margin: 0;
}
.tab-body-unseen{
  position: relative;
  border-bottom: 1px solid var(--boot-gray);
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  background: rgb(99,182,202);
  background: linear-gradient(117deg, rgba(99,182,202,1) 0%, rgba(99,182,202,1) 41%, rgba(185,250,255,1) 52%, rgba(99,182,202,1) 63%, rgba(99,182,202,1) 100%);
  background-repeat: repeat-x;
  background-size: 4000px, 60px;
  background-position: 160%, 50%;
  color: black;
  margin: 0;
  animation: 6s infinite normal linear notification;
}
@keyframes notification{
  0%{
    background-position: 100%, 50%;
  }
  100%{
    background-position: -100%, 50%;
  }
}
.delete-chat{
  position: absolute;
  top:5px;
  right: 5px;
  color: red;
  display: none;
}
.tab-body-seen:hover .delete-chat{
  display: flex;
}
.tab-body-unseen:hover .delete-chat{
  display: flex;
}
.inactive-user{
 margin: 0;
}
.highlighted-user{
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.online{
  height: 15px;
  width: 15px;
  position: absolute;
  transform: translate(25px, -10px);
  z-index: 1;
  border-radius: 50%;
  background-color: greenyellow;
  border: 2px solid var(--holo-blue )
}
.offline{
  height: 15px;
  width: 15px;
  position: absolute;
  transform: translate(25px, -10px);
  z-index: 1;
  border-radius: 50%;
  background-color: rgb(117, 117, 117);
  border: 2px solid var(--holo-blue )
}
.truncate{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 175px;
}
.friendlist .form-control{
  border-radius: 0px;
  border: none;
}
.add-friends-show{
  background-color: var(--holo-blue);
  position: absolute;
  width: 248px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--boot-gray);
  pointer-events: none;
}
.add-friends-hide{
  display: none;
}
.add-friend-button{
position: absolute;
right: 10px;
top: 6px;
pointer-events: all;
cursor: pointer;
font-size: 18pt;
color: white;
}