@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&family=Inter&family=Noto+Emoji&family=Roboto&family=Splash&family=Work+Sans&display=swap');
:root{
  --bg-color: #B99182;
  --holo-blue-alpha: rgba(185, 250, 255, 0.6);
  --holo-blue-light: rgba(185, 250, 255, 1);
  --holo-blue: rgb(99, 182, 202);
  --text-blue: #007bff; 
  --garage-beige: #c0b494;
  --boot-gray: #ced4da;
}
body {
  margin: 0;
  font-family: Allerta Stencil, -apple-system, BlinkMacSystemFont,  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  font-family: Allerta Stencil, -apple-system, BlinkMacSystemFont,  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
.pointer-cursor-class{
cursor: pointer;
}
.default-cursor-class{
cursor: default;
}
