.transparent-footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    background: none;
    background-color: none;
    color: white;
    bottom: 0px;
    margin-bottom: 10px;
    padding-inline: 20px;
    pointer-events: none;
}
.transparent-footer *{
    color: white;
    pointer-events: all;
}
.garage-footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    background: none;
    background-color: none;
    color: black;
    bottom: 0px;
    margin-bottom: 10px;
    padding-inline: 20px;
    pointer-events: none;
}
.garage-footer *{
    color: var(--holo-blue);
    pointer-events: all;
}
.contact-icons a{
    margin-left: 12px;
}
.field-footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    background: none;
    background-color: none;
    color: white;
    bottom: 0px;
    margin-bottom: 10px;
    padding-inline: 20px;
    pointer-events: none;
}
.field-footer *{
    color: black;
    pointer-events: all;
}
@media(max-width:720px){
    .name-link{
        z-index: -1;
    }
}
@media(max-width:599px){
    .garage-footer{
        z-index: 100;
    position:fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-color);
    background-color: var(--bg-color);
    color: black;
    bottom: 0px;
    margin-bottom: 5px;
    } 
    .garage-footer *{
        color: black;
    }
    .transparent-footer *{
        color: black;
    }
    .transparent-footer{
    z-index: 100;
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-color);
    background-color: var(--bg-color);
    color: black;
    bottom: 0px;
    margin-bottom: 5px;

}
.contact-icons a{
    margin-right: 12px;
}
.field-footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    background: none;
    background-color: none;
    color: white;
    bottom: 0px;
    margin-bottom: 10px;
    padding-inline: 20px;

}
}
@media(max-height:750px){
    .field-footer{
        display: none;
    
    }
}
@media(max-width:240px){
    .garage-footer{
        display: none;
    
    }
}