.right-tab{
  position: fixed;
  top: 390px;
  right: -20px;
  width: 15px;
  height:80px;
  z-index: 2;
  pointer-events: all;
  font-size: 20pt;
  border-radius: 50% 0px 0px 50% !important;
  padding-inline: 30px;
  border-radius: 0px 0px 4px 4px;
  transition: border-radius .3s, width .3s, right .3s;
  color: white;
  background-color: var(--holo-blue);

  
}
.right-tab:hover{
  color: white;
  background-color: var(--holo-blue);
 
  width: 35px;
  right: -2px;
  transition: border-radius .3s, width .3s, right .3s;
  z-index: 2;
  border-radius: 20px 0px 0px 20px !important;
}
.right-icon-container{
  position: absolute;
  top: 17px;
  left: 10px;
  transition: top .3s, left .3s;
}
.right-tab:hover .right-icon-container{
  position: absolute;
  top: 0px;
  left: 16px;
  transition: top .3s, left .3s;
  z-index: 2;
}
.right-tab-label{
  opacity: 0;
  font-size: 10pt;
  position: absolute;
  top: 40px;
  left: 30px;
  padding: 0;
  transition: top .3s, left .3s,  opacity .3s;
  line-height: 90%;
}
.right-tab:hover .right-tab-label{
  font-size: 10pt;
  position: absolute;
  top: 40px;
  left: 20px;
  padding: 0;
  transition: top .3s, left .3s,  opacity .3s;
  z-index: 2;
  width: 20px;
  display: flex;
  justify-content: center;
  opacity: 1;
  line-height: 90%;
}
