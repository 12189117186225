.friends-tab{
  display: none;
}
.friends-tab:hover{
  color: white;
  background-color: var(--holo-blue);
  border: none;
}
.friends-label-Show{
  font-size: 10pt;
  position: absolute;
  top: 35px;
  left: -10px;
  padding: 0;
  transition: top .3s, left .3s,  opacity .3s;
  line-height: 90%;
  rotate: 90deg;
}
.friends-label-Hide{
  font-size: 10pt;
  position: absolute;
  top: 35px;
  left: -5px;
  padding: 0;
  transition: top .3s, left .3s,  opacity .3s;
  line-height: 90%;
  rotate: 90deg;
}
/* .friends-tab:hover .friends-label{
  font-size: 10pt;
  position: absolute;
  top: 45px;
  left: 30px;
  padding: 0;
  transition: top .3s, left .3s,  opacity .3s;
  z-index: 2;
  width: 20px;
  display: flex;
  justify-content: center;
  opacity: 1;
  line-height: 90%;
} */

@media (max-width:657px){
  .friends-tab{
    position: absolute;
    top: 195px;
    right: 0px;
    width: 20px;
    height:80px;
    z-index: 2;
    pointer-events: all;
    font-size: 20pt;
    border-radius:  0px 4px 4px 0px;
    transition: border-radius .3s, width .3s, right .3s;
    color: black;
    background-color: var(--holo-blue-alpha);
    border: 1px solid var(--boot-gray);  
    border-left: none;
    padding: 0px;
    display: flex;
  }
}