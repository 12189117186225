.garage-UI{
    overflow: hidden;
    height: 100vh;
    position: fixed;
}
@media(max-width: 599px){
    .garage-UI{
        overflow: hidden;
        height: 100vh;
        position: fixed;
        bottom: 48px;
    }
}