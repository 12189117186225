.avatar{
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border:1px solid black;
    display: flex;
}
#dropdown-variants-light{
    border: 1px solid var(--bg-color) !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--bg-color);
}
#dropdown-variants-light:hover{
    background-color: var(--bg-color);
}
#dropdown-variants-light:focus{
    border: none;
    box-shadow: none;
}
.dropdown-menu.show{
    background-color: var(--holo-blue);
    border: none;
}
.dropdown-divider{
    border-color: var(--holo-blue-alpha);
}
.dropdown-menu.show .dropdown-custom-item{
    color: white;    
}
.dropdown-menu.show .dropdown-custom-item:hover{
    background-color: var(--holo-blue-alpha);
    color: white;    
}
#dropdown-variants-light h2{
    font-size: 32pt;
}
#dropdown-variants-light{
    font-size: 20pt;
}
.drop-container{
    display: flex;
    flex-direction: row;
    background-color: var(--bg-color);
}
@media(min-width: 291px){
    .responsive{
    display: none;

    }    
}
@media(max-width: 290px){
    .dropdown-menu.show{
        min-width: 4rem !important;
    }
}
@media(min-width: 660px){
    .hide-when-big{
        display: none;
    }
}