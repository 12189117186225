.asset-thumbnail {
  width: 40px;
  height: 40px;
}
.uploaded-item{
  position: relative;
  border-top: 1px solid var(--boot-gray);
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  background-color: var(--holo-blue);
  color: black;
  margin: 0;
}

.delete-tab{
  position: absolute;
  top:5px;
  right: 5px;
  color: red;
  display: none;
}

.truncate{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
  margin-left: 10px;
}
