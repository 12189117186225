.info-tab{
  position: fixed;
  top: 195px;
  right: -20px;
  width: 15px;
  height:80px;
  z-index: 2;
  pointer-events: all;
  font-size: 20pt;
  border-radius: 50% 0px 0px 50% ;
  padding-inline: 30px;
  transition: border-radius .3s, width .3s, right .3s;
  color: white;
  background-color: var(--holo-blue);
  border: none;
  
}
.info-tab:hover{
  color: white;
  background-color: var(--holo-blue);
  border: none;
  width: 80px;
  right: -2px;
  transition: border-radius .3s, width .3s, right .3s;
  z-index: 2;
  border-radius: 20px 0px 0px 20px;
}
.info-icon-container{
  position: absolute;
  top: 18px;
  left: 10px;
  transition: top .3s, left .3s;
}
.info-tab:hover .info-icon-container{
  position: absolute;
  top: 5px;
  left: 28px;
  transition: top .3s, left .3s;
  z-index: 2;
}
.info-tray{
  position: fixed;
  top: 195px;
  right: calc(135px);
  width: 270px;
  height:400px;
  z-index: 5;
  border-radius: 10px;
  background-color:  var(--holo-blue-alpha);
  cursor: grab;
}
.info-tray-close-icon{
  color: white;
  font-size: 20pt;
position: absolute;
right: 4px;
height: 50px;
top:-15px;
cursor:pointer;
}
.info-tray-details{
  position: absolute;
  padding: 10px;
  top: 20px;
  left: 10px;
  width: calc(100% - 20px);
  height:calc(100% - 30px);
  z-index: 3;
  color: white;
  background-color: var(--holo-blue);
  border-radius: 10px;
  cursor: default;
  overflow: hidden;
  overflow-y: auto;
}

.info-tray-closed{
  background-color: black;
  position: fixed;
  top: 195px;
  right: -300px;
  width: 250px;
  height:80px;
  z-index: 1;
  display: none;
}
.info-label{
  opacity: 0;
  font-size: 10pt;
  position: absolute;
  top: 45px;
  left: 30px;
  padding: 0;
  transition: top .3s, left .3s,  opacity .3s;
  line-height: 90%;
}
.info-tab:hover .info-label{
  font-size: 10pt;
  position: absolute;
  top: 45px;
  left: 30px;
  padding: 0;
  transition: top .3s, left .3s,  opacity .3s;
  z-index: 2;
  width: 20px;
  display: flex;
  justify-content: center;
  opacity: 1;
  line-height: 90%;
}
.drag-container{
  position: fixed;
  width: 100vw;
  height: calc(100vh - 82px);
  bottom: 0;
  z-index: 1;
  pointer-events: none;

}
.drag-container *{
pointer-events: all;
}
@media (max-width:390px){
  .info-tray{
    position: fixed;
    top: 195px;
    left: calc(50vw - 135px);
    width: 270px;
    height:400px;
    z-index: 5;
    border-radius: 10px;
    background-color:  var(--holo-blue-alpha);
    cursor: grab;
  }
}