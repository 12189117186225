.canvas-container{
    height:100vh;
    width: 100vw;
    z-index: 0;
}
.garage-loader-container{
    position: fixed;
    top: -50vh;
    left: -50vw;
}
